<template>
  <div class="users-stats stats">
    <h4 v-if="!noCharts" class="primary--text mb-4">
      {{ $t("common.users") }}
    </h4>

    <div class="stat-boxes">
      <StatBox
        v-for="(box, i) in boxes"
        :key="i"
        :label="box.label"
        :value="box.value"
        :loading="loading"
      />
    </div>

    <LineChart
      v-if="!noCharts"
      :title="$t('dashboard.newUsers')"
      type="user"
      height="250px"
      class="my-15"
    />

    <div v-if="!noCharts" class="d-flex flex-wrap" style="gap: 100px">
      <PieChart
        :title="$t('dashboard.countriesChart')"
        :data="stats.registrations_by_country"
        :loading="loading"
        height="160px"
      />

      <PieChart
        :title="$t('dashboard.favouriteClubs')"
        :data="stats.favourite_football_clubs"
        :loading="loading"
        height="160px"
      />
    </div>
  </div>
</template>

<script>
import LineChart from "../charts/LineChart.vue";
import PieChart from "../charts/PieChart.vue";
import StatBox from "../StatBox.vue";

export default {
  components: { LineChart, PieChart, StatBox },

  props: {
    noCharts: Boolean,
  },

  data: () => ({
    loading: false,
    stats: {
      total_users_number: null,
      total_logged_in_users: null,
      total_inactive_users: null,
      deactivated_users: null,
      average_online_time_overall_minutes: null,
      registrations_by_country: {},
      favourite_football_clubs: {},
    },
  }),

  computed: {
    boxes() {
      return [
        {
          label: this.$t("dashboard.totalUsers"),
          value: this.stats.total_users_number,
        },
        {
          label: this.$t("dashboard.loggedIn"),
          value: this.stats.total_logged_in_users,
        },
        {
          label: this.$t("dashboard.inactiveUsers"),
          value: this.stats.total_inactive_users,
        },
        {
          label: this.$t("dashboard.deactivatedUsers"),
          value: this.stats.deactivated_users,
        },
        {
          label: this.$t("dashboard.averageOnline"),
          value: `${Math.round(
            this.stats.average_online_time_overall_minutes
          )} min`,
        },
      ];
    },
  },

  created() {
    this.loading = true;
    this.$store
      .dispatch("admin/statsByType", "user")
      .then((stats) => {
        this.stats = stats;
      })
      .catch(() => {
        this.$toast.error("Error while fetching stats for users.");
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style></style>
