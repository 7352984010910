<template>
  <div class="pie-chart">
    <!-- Header -->
    <div class="d-flex flex-wrap align-center">
      <!-- Title -->
      <h4 v-if="title" class="primary--text mb-3">{{ title }}</h4>

      <v-spacer />

      <!-- Interval (daily, monthly, yearly) -->
      <div class="d-flex pr-5 mb-4" style="gap: 10px">
        <v-btn
          v-for="item in ['day', 'month', 'year']"
          :key="item"
          small
          rounded
          :loading="interval === item && $loading('admin/chartByType') === type"
          :color="interval === item ? 'primary' : 'secondary'"
          @click="interval = item"
        >
          {{ $t(item === "day" ? "common.daily" : `common.${item}ly`) }}
        </v-btn>
      </div>
    </div>

    <!-- Chart -->
    <div class="canvas-wrapper" :style="{ height: height || 'unset' }">
      <canvas ref="canvas">
        Your browser does not support the canvas element.
      </canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  props: {
    title: String,
    height: String,
    type: String,
  },

  data: () => ({
    chart: null,
    interval: "month",
    data: [],
  }),

  watch: {
    interval: "fetchData",
  },

  created() {
    this.fetchData();
  },

  methods: {
    initChart() {
      const config = {
        type: "line",
        data: this.getData(),
        options: {
          maintainAspectRatio: false,
          fill: true,
          borderColor: "#38B54A",
          backgroundColor: "#0B2540",
          tension: 0.5,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                precision: 0,
              },
            },
          },
        },
      };

      this.chart = new Chart(this.$refs.canvas, config);
    },

    getData() {
      if (this.interval === "year" && this.data.length === 1) {
        this.data.unshift({ date: this.data[0].date - 1, total: 0 });
      }

      const labels = this.data.map((i) => i.date);
      const values = this.data.map((i) => i.total);

      return {
        labels,
        datasets: [{ label: this.title || null, data: values }],
      };
    },

    fetchData() {
      this.$store
        .dispatch("admin/chartByType", {
          type: this.type,
          interval: this.interval,
        })
        .then((data) => {
          this.data = data;

          if (this.chart) {
            this.chart.data = this.getData();
            this.chart.update();
          } else {
            this.initChart();
          }
        })
        .catch(() => {
          this.$toast.error(
            `Error while fetching chart data for type [${this.type}].`
          );
        });
    },
  },
};
</script>

<style></style>
