<template>
  <div class="pie-chart">
    <h4 v-if="title" class="primary--text mb-4">{{ title }}</h4>

    <div class="canvas-wrapper" :style="{ height: height || 'unset' }">
      <spinner v-if="loading" medium center />

      <template v-show="!loading">
        <canvas ref="canvas">
          Your browser does not support the canvas element.
        </canvas>
      </template>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  props: {
    data: [Array, Object],
    title: String,
    height: String,
    loading: Boolean,
  },

  data: () => ({
    chart: null,
  }),

  watch: {
    data() {
      this.initChart();
    },
  },

  methods: {
    initChart() {
      const labels = Object.keys(this.data);
      const values = Object.values(this.data).map((i) => parseFloat(i));

      const config = {
        type: "doughnut",
        data: {
          labels,
          datasets: [{ label: this.title || null, data: values }],
        },
        options: {
          backgroundColor: colors,
          borderWidth: 0,
          hoverOffset: 4,
          cutout: "80%",
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
              onClick: (e) => e.stopPropagation(),
            },
          },
        },
      };

      this.chart = new Chart(this.$refs.canvas, config);
    },
  },
};

const colors = [
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
  "#b77322",
  "#16d620",
  "#b91383",
  "#f4359e",
  "#9c5935",
  "#a9c413",
  "#2a778d",
  "#668d1c",
  "#bea413",
  "#0c5922",
  "#743411",
];
</script>

<style></style>
